import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import TabList from '@/components/TabList.vue';
import { systemModule } from '@/store/modules/system';
import { aboutModule } from '@/store/modules/about';
var RecruitInfo = (function (_super) {
    __extends(RecruitInfo, _super);
    function RecruitInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RecruitInfo.prototype.mounted = function () {
        aboutModule.getRecruitInfo();
    };
    Object.defineProperty(RecruitInfo.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecruitInfo.prototype, "titleBar", {
        get: function () {
            return [
                {
                    id: 43,
                    name: this.$t('recruitInfo.society')
                },
                {
                    id: 44,
                    name: this.$t('recruitInfo.campus')
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecruitInfo.prototype, "curentID", {
        get: function () {
            return aboutModule.recruitID;
        },
        set: function (val) {
            aboutModule.setRecruitID(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecruitInfo.prototype, "recruitInfo", {
        get: function () {
            return aboutModule.recruitInfo;
        },
        enumerable: false,
        configurable: true
    });
    RecruitInfo.prototype.cellClick = function (row, column) {
        if (column.property === 'position') {
            this.$router.push({
                name: 'job',
                params: {
                    id: row.id
                }
            });
        }
    };
    RecruitInfo = __decorate([
        Component({
            components: { CustomHead: CustomHead, TabList: TabList }
        })
    ], RecruitInfo);
    return RecruitInfo;
}(Vue));
export default RecruitInfo;
